import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import {
  ButtonGroup,
  Flex,
  IconButton,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Field } from "../types";
import EditableTextField from "./EditableTextField";

interface EditableGroupProps {
  initialFields: Field[];
  onFieldsChange: (fields: Field[]) => void; // Callback to send updated fields to parent
}

const EditableGroup: React.FC<EditableGroupProps> = ({
  initialFields,
  onFieldsChange,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [fields, setFields] = useState<Field[]>(initialFields);
  const [tmpFields, setTmpFields] = useState<Field[]>([]);

  const handleSave =
    (fieldName: string) => (newValue: string, isValid: boolean) => {
      const newFields = fields.map((field) =>
        field.key === fieldName
          ? { ...field, value: newValue, isValid: isValid }
          : field
      );
      setFields(newFields);
    };

  const saveAll = () => {
    const allValid = fields.every((field) => field.isValid !== false);
    if (!allValid) {
      console.error("Not all fields are valid");
      return;
    }
    onFieldsChange(fields); // Trigger callback with updated fields
    setIsEditing(false);
  };

  const cancelAll = () => {
    setFields([...tmpFields]); // Revert to previous values
    setIsEditing(false);
  };

  const startEdit = () => {
    setIsEditing(true);
    setTmpFields([...fields]); // Make a copy of fields to revert on cancel
  };

  useEffect(() => {
    // we got new fields to display
    setFields([...initialFields]);
  }, [initialFields]);

  const textColor = useColorModeValue("gray.700", "white");

  const allValid = fields.every((field) => field.isValid !== false);

  const tdStyle = {
    px: 2, // padding left and right
    py: 2, // padding top and bottom
  };
  return (
    <Flex width="50%">
      <Table
        variant="unstyled"
        size="md"
        sx={{ tableLayout: "auto", width: "auto" }}
      >
        <Tbody>
          {fields.map((field) => (
            <Tr key={field.key}>
              <Td sx={tdStyle}>
                <Text fontSize="md" color={textColor} fontWeight="bold">
                  {field.title}:
                </Text>
              </Td>
              <Td sx={tdStyle}>
                <EditableTextField
                  field={field}
                  onChange={handleSave(field.key)}
                  isEditing={isEditing}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Spacer />
      {isEditing ? (
        <ButtonGroup size="sm" mt={4} verticalAlign="top">
          <IconButton
            aria-label="Submit"
            icon={<CheckIcon />}
            onClick={saveAll}
            isDisabled={!allValid}
          />
          <IconButton
            aria-label="Cancel"
            icon={<CloseIcon />}
            onClick={cancelAll}
          />
        </ButtonGroup>
      ) : (
        <IconButton
          aria-label="Edit"
          icon={<EditIcon />}
          onClick={startEdit}
          variant="ghost"
          size="lg"
          alignSelf="start"
        />
      )}
    </Flex>
  );
};

export default EditableGroup;
