// UserContext.tsx
import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { clearJwt, getUserData, setJwt } from "../api";
import { UserDataType } from "types";

interface UserContextType {
  userData: any;
  setAuthToken: (token: string) => void;
  logout: () => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userData, setUserData] = useState<UserDataType | null>(null);

  const fetchUserData = async () => {
    const userData = await getUserData();
    console.log("UserContext fetchUserData", userData);
    if (userData) {
      setUserData(userData);
    }
  };

  useEffect(() => {
    //// TEMPORARY
    setAuthToken(
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTcyNTU2OTY2NCwianRpIjoiNTc2OTNiODMtYmVjOC00Y2NhLTg5OGYtZTRkZGYzZjU5NGJlIiwidHlwZSI6ImFjY2VzcyIsInN1YiI6eyJ1c2VyX2lkIjoyOCwicm9sZSI6InVzZXIiLCJjcmVhdGVkX2F0IjoiMjAyNC0wOS0wNVQxMzo1NDoyNFoiLCJ0ZXN0X3Rva2VuIjp0cnVlLCJzZXJpYWwiOiIxMjM0NTYifSwibmJmIjoxNzI1NTY5NjY0LCJjc3JmIjoiMWViNGMxNjAtYTFmNC00MTc4LWI3MGYtYjFiYWM1NGY1MTdiIiwiZXhwIjoxNzU3MDE5MjY0fQ.2tLHfbVLKo6t-XNkMw_7o4_Moqacsg9ccZCv-h5fuUc"
    );
    //// TEMPORARY

    const init = async () => {
      fetchUserData();
    };

    init().catch(console.error);
  }, []);

  const setAuthToken = (token: string) => {
    setJwt(token);
    fetchUserData();
  };

  const logout = () => {
    clearJwt();
    delete axios.defaults.headers.common["Authorization"];
    setUserData(null);
  };

  return <UserContext.Provider value={{ userData, setAuthToken, logout }}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

/*
 Auth/SignIn.js:
  User enters email address to receive a login link.
    -> api.ts.login(email)
      -> POST /api/v1/auth {"email": "xxx@yyy"}

User clicks on the login link in the email.
    http://hostname/auth&lt=<login-token>
    -> Auth.tsx
      -> api.ts.checkLoginToken(loginToken)
        -> GET /api/v1/auth?lt=<login-token>
        -> returns a JWT token
      -> api.ts.getUserData()
        -> GET /api/v1/user/0
        -> returns userData

 The JWT token is used for all other API calls.

lt = login token
       <uuid><user_id>
  uuid is 36 chars
  user_id is variable length
*/
