/* eslint-disable react/prop-types */
// Chakra imports
import { Flex, Grid, Image, SimpleGrid, useColorModeValue, Badge, VStack, Text } from "@chakra-ui/react";
// assets
import peopleImage from "../../../assets/img/people-image.png";
import logoChakra from "../../../assets/svg/logo-white.svg";
import BarChart from "../../../components/Charts/BarChart";
import DonutChart from "../../../components/DonutChart";
import LineChart from "../../../components/LineChart";
// Custom icons
import { CartIcon, DocumentIcon, GlobeIcon, WalletIcon } from "../../../components/Icons/Icons.js";
import React from "react";
import { dashboardTableData, timelineData } from "../../../variables/general";
import ActiveUsers from "./components/ActiveUsers";
import BuiltByDevelopers from "./components/BuiltByDevelopers";
import MiniStatistics from "./components/MiniStatistics";
import OrdersOverview from "./components/OrdersOverview";
import Projects from "./components/Projects";
import SalesOverview from "./components/SalesOverview";
import WorkWithTheRockets from "./components/WorkWithTheRockets";
// import JimTable from "./components/JimTable";
import JimTable from "./components/JimTable";

import { createColumnHelper } from "@tanstack/react-table";

const data = [
  {
    fromUnit: "inches",
    toUnit: "Online",
    factor: 25.4,
  },
  {
    fromUnit: "feet",
    toUnit: "Offline",
    offLineSince: "2021-10-01",
    factor: 30.48,
  },
  {
    fromUnit: "yards",
    toUnit: "Offline",
    offLineSince: "2021-10-01",
    factor: 0.91444,
  },
];
const highlightCondition = function (row, columnId) {
  return columnId === "factor" && row.getValue(columnId) > 20;
};

const StatusBadge = ({ value, value2 }) => {
  // Use useColorModeValue hook to set colors based on the current color mode
  const bgStatus = useColorModeValue("gray.300", "gray.600");
  const colorStatus = useColorModeValue("white", "gray.400");

  return (
    <VStack>
      <Badge bg={value === "Online" ? "green.400" : bgStatus} color={value === "Online" ? "white" : colorStatus} fontSize="14px" p="3px 10px" borderRadius="8px">
        {value}
      </Badge>
      {value2 && (
        <Text fontSize="sm" color="gray.500">
          Since {value2}
        </Text>
      )}
    </VStack>
  );
};

const columns = [
  { title: "To convert", field: "fromUnit" },
  {
    title: "Status",
    field: (rowData) => <StatusBadge value={rowData.toUnit} value2={rowData?.offLineSince} />,
  },
  { title: "Multiply by", field: "factor", isNumeric: true },
];

// console.log(columns);
// console.log(transformColumns(jcolumns));
// const columns = transformColumns(jcolumns);

export default function DashMain() {
  const iconBoxInside = useColorModeValue("white", "white");

  const [selectedRowId, setSelectedRowId] = React.useState(null);

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing="24px">
        <MiniStatistics title={"Today's Moneyz"} amount={"$53,000"} percentage={55} icon={<WalletIcon h={"24px"} w={"24px"} color={iconBoxInside} />} />
        <MiniStatistics title={"Today's Users"} amount={"2,300"} percentage={5} icon={<GlobeIcon h={"24px"} w={"24px"} color={iconBoxInside} />} />
        <MiniStatistics title={"New Clients"} amount={"+3,020"} percentage={-14} icon={<DocumentIcon h={"24px"} w={"24px"} color={iconBoxInside} />} />
        <MiniStatistics title={"Total Sales"} amount={"$173,000"} percentage={8} icon={<CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />} />
      </SimpleGrid>
      <Grid templateColumns={{ md: "1fr", lg: "1.8fr 1.2fr" }} templateRows={{ md: "1fr auto", lg: "1fr" }} my="26px" gap="24px">
        <BuiltByDevelopers
          title={"Built by Developers"}
          name={"Purity UI Dashboard"}
          description={"From colors, cards, typography to complex elements, you will find the full documentation."}
          image={<Image src={logoChakra} alt="chakra image" minWidth={{ md: "300px", lg: "auto" }} />}
        />
        <WorkWithTheRockets
          backgroundImage={peopleImage}
          title={"Work with the rockets"}
          description={"Wealth creation is a revolutionary recent positive-sum game. It is all about who takes the opportunity first."}
        />
      </Grid>
      <Grid templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr" }} templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }} gap="24px" mb={{ lg: "26px" }}>
        <ActiveUsers title={"Active Users"} percentage={23} chart={<BarChart />} />
        <SalesOverview
          title={"Sales Overview"}
          percentage={5}
          // chart={<DonutChart />}
          chart={<LineChart />}
        />
      </Grid>
      <Grid templateColumns={{ sm: "1fr", md: "1fr 1fr", lg: "2fr 1fr" }} templateRows={{ sm: "1fr auto", md: "1fr", lg: "1fr" }} gap="24px">
        <JimTable
          title={"Unit Converter"}
          columnDefs={columns}
          data={data}
          highlightCondition={highlightCondition}
          selectedRowId={selectedRowId}
          onRowSelect={setSelectedRowId} // Assuming `setSelectedRowId` updates state in the parent component
        />
        {/* <Projects
          title={"Projects"}
          amount={30}
          captions={["Companies", "Members", "Budget", "Completion"]}
          data={dashboardTableData}
        /> */}
        <OrdersOverview title={"Orders Overview"} amount={30} data={timelineData} />
      </Grid>
    </Flex>
  );
}
