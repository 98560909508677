import React from "react";
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from "react-router-dom";

import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "./layouts/Auth.js";
import { UserProvider } from "./contexts/UserContext";
import { useEffect } from "react";
import { setupAxiosInterceptor } from "./api";

// Setup interceptor immediately, using a dummy navigate function
setupAxiosInterceptor(() => (window.location.href = "/login"));

const App = () => {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
};

const AppContent = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Update the interceptor with the real navigate function once available
    setupAxiosInterceptor(navigate);
  }, []);

  return (
    <UserProvider>
      <Routes>
        <Route path="/auth/*" element={<AuthLayout />} />
        <Route path="/admin/*" element={<AdminLayout />} />
        <Route path="/" element={<Navigate replace to="/admin" />} />
      </Routes>
    </UserProvider>
  );
};

export default App;
