/* eslint-disable react/prop-types */
// Chakra imports
import { Box, Button, Flex, HStack, Link, Text, useColorModeValue } from "@chakra-ui/react";
import { MainLogo, DocumentIcon, HomeIcon, PersonIcon, RocketIcon } from "../../components/Icons/Icons";
import SidebarResponsive from "../../components/Sidebar/SidebarResponsive";
import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
// import routes from "../../routes.js";
export default function AuthNavbar(props) {
  const [open, setOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  const { logo, logoText, secondary, ...rest } = props;
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  };
  // Chakra color mode
  let navbarIcon = useColorModeValue("gray.700", "gray.200");
  let mainText = useColorModeValue("gray.200", "gray.200");
  // let navbarBg = useColorModeValue(  RP commented out
  //   "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
  //   "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  // );
  let navbarBg = useColorModeValue(
    // "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
    "#002056",
    "linear-gradient(112.83deg, rgba(0, 32, 86, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  let navbarBorder = useColorModeValue("1.5px solid #FFFFFF", "1.5px solid rgba(255, 255, 255, 0.31)");
  let navbarShadow = useColorModeValue("0px 7px 23px rgba(0, 0, 0, 0.05)", "none");
  let navbarFilter = useColorModeValue("none", "drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))");
  let navbarBackdrop = "blur(21px)";
  let bgButton = useColorModeValue("linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)", "gray.800");
  let navbarPosition = "fixed";
  let colorButton = "white";
  if (props.secondary === true) {
    navbarIcon = "white";
    // navbarBg = "none";
    navbarBorder = "none";
    navbarShadow = "initial";
    navbarFilter = "initial";
    navbarBackdrop = "none";
    bgButton = "white";
    colorButton = "gray.700";
    mainText = "white";
    navbarPosition = "absolute";
  }
  var brand = (
    <Link href="https://actineon.com" /* RP */ target="_blank" display="flex" lineHeight="100%" fontWeight="bold" justifyContent="center" alignItems="center" color={mainText}>
      <MainLogo h="30px" />
      <Text fontSize="md" mt="3px" pl="50px">
        {logoText}
      </Text>
    </Link>
  );
  // var linksAuth = (
  //   <HStack display={{ sm: "none", lg: "flex" }}>
  //     <NavLink to="/admin/dashboard">
  //       <Button
  //         fontSize="sm"
  //         ms="0px"
  //         px="0px"
  //         me={{ sm: "2px", md: "16px" }}
  //         color={navbarIcon}
  //         variant="transparent-with-icon"
  //         leftIcon={<HomeIcon color={navbarIcon} w="12px" h="12px" me="0px" />}
  //       >
  //         <Text>Dashboard</Text>
  //       </Button>
  //     </NavLink>
  //     <NavLink to="/admin/profile">
  //       <Button
  //         fontSize="sm"
  //         ms="0px"
  //         px="0px"
  //         me={{ sm: "2px", md: "16px" }}
  //         color={navbarIcon}
  //         variant="transparent-with-icon"
  //         leftIcon={
  //           <PersonIcon color={navbarIcon} w="12px" h="12px" me="0px" />
  //         }
  //       >
  //         <Text>Profile</Text>
  //       </Button>
  //     </NavLink>
  //     <NavLink to="/auth/signup">
  //       <Button
  //         fontSize="sm"
  //         ms="0px"
  //         px="0px"
  //         me={{ sm: "2px", md: "16px" }}
  //         color={navbarIcon}
  //         variant="transparent-with-icon"
  //         leftIcon={
  //           <RocketIcon color={navbarIcon} w="12px" h="12px" me="0px" />
  //         }
  //       >
  //         <Text>Sign Up</Text>
  //       </Button>
  //     </NavLink>
  //     <NavLink to="/auth/signin">
  //       <Button
  //         fontSize="sm"
  //         ms="0px"
  //         px="0px"
  //         me={{ sm: "2px", md: "16px" }}
  //         color={navbarIcon}
  //         variant="transparent-with-icon"
  //         leftIcon={
  //           <DocumentIcon color={navbarIcon} w="12px" h="12px" me="0px" />
  //         }
  //       >
  //         <Text>Sign In</Text>
  //       </Button>
  //     </NavLink>
  //   </HStack>
  // );
  return (
    <Flex
      position={navbarPosition}
      top="16px"
      left="30%"
      transform="translate(-50%, 0px)"
      background={navbarBg}
      border={navbarBorder}
      boxShadow={navbarShadow}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      borderRadius="10"
      px="16px"
      py="22px"
      mx="auto"
      // width="1044px"
      maxW="90%"
      alignItems="center"
    >
      <Flex w="100%" justifyContent={{ sm: "start", lg: "space-between" }}>
        {brand}
        {/* <Box
          ms={{ base: "auto", lg: "0px" }}
          display={{ base: "flex", lg: "none" }}
        >
          <SidebarResponsive
            logoText={props.logoText}
            secondary={props.secondary}
            routes={routes}
            // logo={logo}
            {...rest}
          />
        </Box> */}
        {/* {linksAuth}
        <Link href="https://reliablepower.com/product/purity-ui-dashboard">
          <Button
            bg={bgButton}
            color={colorButton}
            fontSize="xs"
            variant="no-hover"
            borderRadius="35px"
            px="30px"
            display={{
              sm: "none",
              lg: "flex",
            }}
          >
            Free Download
          </Button>
        </Link> */}
      </Flex>
    </Flex>
  );
}

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string,
};
