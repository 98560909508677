import { Badge, VStack } from "@chakra-ui/react";
import React from "react";

const SessionStatusBadge: React.FC<{ value: string }> = ({ value }) => {
  const colorScheme =
    value === "Completed" ? "green" : value === "Denied" ? "red" : "blue";

  return (
    <VStack>
      <Badge colorScheme={colorScheme}>{value}</Badge>
    </VStack>
  );
};

export default SessionStatusBadge;