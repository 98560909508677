/* eslint-disable react/prop-types */
// Chakra imports
import { Flex, Grid, Image, SimpleGrid, useColorModeValue, Badge, VStack, Box, Text, Card, CardHeader, CardBody } from "@chakra-ui/react";
// assets
import peopleImage from "../../../assets/img/people-image.png";
import logoChakra from "../../../assets/svg/logo-white.svg";
import BarChart from "../../../components/Charts/BarChart";
import DonutChart from "../../../components/DonutChart";
import LineChart from "../../../components/LineChart";
import SessionStatusBadge from "../../../components/SessionStatusBadge";
// Custom icons
import { CartIcon, DocumentIcon, GlobeIcon, WalletIcon } from "../../../components/Icons/Icons.js";
import React from "react";
import { dashboardTableData, timelineData } from "../../../variables/general";
import ActiveUsers from "../DashMain/components/ActiveUsers";
import BuiltByDevelopers from "../DashMain/components/BuiltByDevelopers";
import MiniStatistics from "../DashMain/components/MiniStatistics";
import OrdersOverview from "../DashMain/components/OrdersOverview";
import Projects from "../DashMain/components/Projects";
import SalesOverview from "../DashMain/components/SalesOverview";
import WorkWithTheRockets from "../DashMain/components/WorkWithTheRockets";
// import JimTable from "./components/JimTable";
import JimTable from "../DashMain/components/JimTable";

import { createColumnHelper } from "@tanstack/react-table";
import MainTable from "../../../components/MainTable";

const data = [
  {
    fromUnit: "inches",
    toUnit: "Online",
    factor: 25.4,
  },
  {
    fromUnit: "feet",
    toUnit: "Offline",
    offLineSince: "2021-10-01",
    factor: 30.48,
  },
  {
    fromUnit: "yards",
    toUnit: "Offline",
    offLineSince: "2021-10-01",
    factor: 0.91444,
  },
];
const highlightCondition = function (row, columnId) {
  return columnId === "factor" && row.getValue(columnId) > 20;
};

const StatusBadge = ({ value, value2 }) => {
  // Use useColorModeValue hook to set colors based on the current color mode
  const bgStatus = useColorModeValue("gray.300", "gray.600");
  const colorStatus = useColorModeValue("white", "gray.400");

  return (
    <VStack>
      <Badge bg={value === "Online" ? "green.400" : bgStatus} color={value === "Online" ? "white" : colorStatus} fontSize="14px" p="3px 10px" borderRadius="8px">
        {value}
      </Badge>
      {value2 && (
        <Text fontSize="sm" color="gray.500">
          Since {value2}
        </Text>
      )}
    </VStack>
  );
};

const columns = [
  { title: "To convert", field: "fromUnit" },
  {
    title: "Status",
    field: (rowData) => <StatusBadge value={rowData.toUnit} value2={rowData?.offLineSince} />,
  },
  { title: "Multiply by", field: "factor", isNumeric: true },
];

// console.log(columns);
// console.log(transformColumns(jcolumns));
// const columns = transformColumns(jcolumns);

const ocColumns = [
  { title: "Model", field: "model" },
  { title: "Name", field: "name" },
  { title: "kWh this month", field: "kwh" },
  { title: "Last used", field: "last_used" },
  { title: "Last Seen", field: "status" },
];

const ocDaata = [
  {
    model: "CLB 100",
    name: "Ampeco",
    last_used: "2024-04-12 10:30am",
    status: "2024-04-13",
    user: "Paul Smith",
    kwh: 5,
  },

  {
    model: "CLB 100",
    name: "Ampeco",
    last_used: "2024-01-03 8:22pm",
    status: "2024-01-08",
    user: "Paul Smith",
    kwh: 12,
  },
];

const rsColumns = [
  { title: "Start Time", field: "start_time" },
  {
    title: "Status",
    field: (rowData) => <SessionStatusBadge value={rowData.status} />,
  },
  { title: "User", field: "user" },
  { title: "kWh", field: "kwh" },
];

const rsData = [
  {
    start_time: "2024-04-12 10:30am",
    status: "In Progress",
    user: "Paul Smith",
    kwh: 5,
  },

  {
    start_time: "2024-04-12 10:12am",
    status: "Completed",
    user: "Paul Smith",
    kwh: 12,
  },
  {
    start_time: "2024-04-12 7:18am",
    status: "Completed",
    user: "Ilana Holt",
    kwh: 26,
  },
  {
    start_time: "2024-04-12 4:18am",
    status: "Denied",
    user: "(unknown)",
    kwh: 0,
  },
];

export default function Home() {
  const iconBoxInside = useColorModeValue("white", "white");

  const [selectedRowId, setSelectedRowId] = React.useState(null);

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Flex>
        <Box mr={8}>
          <Card variant="outline">
            <CardHeader pt={5} pl="22px">
              <Text fontSize="lg" fontWeight="bold" mb="6px">
                {"Charger Status"}
              </Text>
            </CardHeader>
            <CardBody p={0}>
              <DonutChart />
            </CardBody>
          </Card>
        </Box>
        <Box flex="1">
          <Card variant="outline" height="340">
            <CardHeader pt={5} pl="22px">
              <Text fontSize="lg" fontWeight="bold" mb="6px">
                {"This Month"}
              </Text>
            </CardHeader>
            <CardBody p={0}>
              {/* Adjust the flexDirection to "row" and ensure the cards take equal width */}
              <Flex flexDirection="row">
                <Card flex="1" mb={4} variant="unstyled" border="none" boxShadow="none">
                  {" "}
                  {/* Set flex="1" to take half space */}
                  <CardHeader pt={5} pl="22px" textAlign="center">
                    <Text fontSize="lg" fontWeight="bold" mb="6px" color="gray.500">
                      {"Energy Used"}
                    </Text>
                  </CardHeader>
                  <CardBody p={0} textAlign="center">
                    <Text fontSize="xxx-large" fontWeight="bold" mb="6px" color="gray.500">
                      837 kWh
                    </Text>
                  </CardBody>
                </Card>
                <Card flex="1" mb={4} variant="unstyled" border="none" boxShadow="none">
                  {" "}
                  {/* Set flex="1" to take half space */}
                  <CardHeader pt={5} pl="22px" textAlign="center">
                    <Text fontSize="lg" fontWeight="bold" mb="6px" color="gray.500">
                      {"Sessions"}
                    </Text>
                  </CardHeader>
                  <CardBody p={0} textAlign="center">
                    <Text fontSize="xxx-large" fontWeight="bold" mb="6px" color="gray.500">
                      52
                    </Text>
                  </CardBody>
                </Card>
              </Flex>
            </CardBody>
          </Card>
        </Box>
      </Flex>
      <Flex mt="50">
        <Card variant="outline" width="100%">
          <CardHeader pt={5} pl="22px">
            <Text fontSize="lg" fontWeight="bold" mb="6px">
              {"Charger Utilization"}
            </Text>
          </CardHeader>
          <CardBody p={0}>
            <LineChart />
          </CardBody>
        </Card>
      </Flex>
      <Flex mt="50">
        <MainTable title={"Offline Chargers"} columnDefs={ocColumns} data={ocDaata} showSearch={false} />
      </Flex>
      <Flex mt="50">
        <MainTable title={"Recent Sessions"} columnDefs={rsColumns} data={rsData} showSearch={false} />
      </Flex>
    </Flex>
  );
}
