import * as React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Card,
  chakra,
  Input,
  InputGroup,
  InputLeftElement,
  CardBody,
  CardHeader,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  SearchIcon,
  TriangleDownIcon,
  TriangleUpIcon,
  UpDownIcon,
} from "@chakra-ui/icons";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  createColumnHelper,
  Row,
} from "@tanstack/react-table";

/**
 * transofrmColunmns()
 *
 * This Transform from:
 *
 * @param columns = [
 *                   { title: "To convert",  field: "fromUnit" },
 *                   { title: "Into" ,       field: "toUnit" },
 *                   { title: "Multiply by", field: "factor", isNumeric: true },
 *                  ];
 *
 * to something like this:
 *
 * @returns  [
 *            columnHelper.accessor("fromUnit", {
 *              cell: (info) => info.getValue(),
 *              header: "To convert"
 *            }),
 *            columnHelper.accessor("toUnit", {
 *              cell: (info) => info.getValue(),
 *              header: "Into"
 *            }),
 *            columnHelper.accessor("factor", {
 *              cell: (info) => info.getValue(),
 *              header: "Multiply by",
 *              meta: {
 *                isNumeric: true
 *              }
 *            })
 *          ];
 */
const columnHelper = createColumnHelper();
function transformColumns(columns: any[]) {
  return columns.map((column) => {
    const meta = column.isNumeric ? { isNumeric: true } : undefined;

    // Create and return the transformed object
    return columnHelper.accessor(column.field, {
      // Check if `field` is a function and call it with row data if it is
      cell: (info: any) => {
        if (typeof column.field === "function") {
          return column.field(info.row.original);
        }
        return info.getValue();
      },
      header: column.title,
      ...(meta && { meta }), // Conditionally add 'meta' if it exists
    });
  });
}



export type DataTableProps<Data extends object> = {
  title: string;
  data: Data[];
  columnDefs: Data[];
  highlightCondition?: (row: Row<unknown>, columnId: string) => boolean;
  selectedRowId: string | number; // Assuming row ID is a string or number
  onRowSelect: (rowId: string | number) => void; // Callback function for selecting a row
};

export default function JimTable<Data extends object>({
  title,
  data,
  columnDefs,
  highlightCondition,
  selectedRowId,
  onRowSelect,
}: DataTableProps<Data>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [searchQuery, setSearchQuery] = React.useState("");

  const selectedBgColor = useColorModeValue("blue.100", "blue.700");

  const columns = React.useMemo(() => {
    return transformColumns(columnDefs);
  }, [columnDefs]); // Only recompute if columnDefs changes

  const filteredData = React.useMemo(() => {
    return data.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [data, searchQuery]); // Only recompute if data or searchQuery changes

  const table = useReactTable({
    columns,
    data: filteredData,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <Card maxH="100%">
      <CardHeader>
        <Heading size="md">{title}</Heading>
      </CardHeader>
      <CardBody>
        <InputGroup ml={5}>
          <InputLeftElement pointerEvents={"none"}>
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            mb={4}
            variant="outline"
          />
        </InputGroup>
        <Table variant="simple" size="sm">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                  const meta: any = header.column.columnDef.meta;
                  return (
                    <Th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      isNumeric={meta?.isNumeric}
                      verticalAlign={"middle"}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}

                      <chakra.span pl="4">
                        {header.column.getIsSorted() ? (
                          header.column.getIsSorted() === "desc" ? (
                            <TriangleDownIcon aria-label="sorted descending" />
                          ) : (
                            <TriangleUpIcon aria-label="sorted ascending" />
                          )
                        ) : (
                          <UpDownIcon aria-label="sortable" color="gray.300" />
                        )}
                      </chakra.span>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr
                key={row.id}
                onClick={() => onRowSelect(row.id)} // Assuming each row data has an `id` property
                bg={row.id === selectedRowId ? selectedBgColor : undefined}
                cursor="pointer"
              >
                {row.getVisibleCells().map((cell) => {
                  // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                  const meta: any = cell.column.columnDef.meta;
                  const isHighlight = highlightCondition
                    ? highlightCondition(row, cell.column.id)
                    : false;

                  return (
                    <Td
                      key={cell.id}
                      isNumeric={meta?.isNumeric}
                      color={isHighlight ? "red.400" : undefined}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
}
