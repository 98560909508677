import React from "react";
import {
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverFooter,
  Button,
  ButtonGroup,
  Heading,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";

// Define the props expected by the component
interface DeleteConfirmationPopoverProps {
  targetId: number; // Assumed to be number, adjust based on your actual data model
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onDelete: (id: number) => void;
}
const DeleteConfirmationPopover: React.FC<DeleteConfirmationPopoverProps> = ({
  targetId,
  isOpen,
  onOpen,
  onClose,
  onDelete,
}) => {
  return (
    <Popover isOpen={isOpen} onClose={onClose} placement="right">
      <PopoverTrigger>
        <IconButton
          icon={<DeleteIcon />}
          aria-label="Delete"
          size="sm"
          _hover={{ bg: "red.100" }}
          onClick={(e) => {
            e.stopPropagation(); // Prevent event bubbling
            onOpen();
          }}
        />
      </PopoverTrigger>
      <PopoverContent width="100%">
        <PopoverArrow />
        <PopoverFooter p={0} m={0}>
            <Button
              colorScheme="red"
              variant="ghost"
              size="sm"
              onClick={() => {
                onDelete(targetId);
                onClose();
              }}
            >
              Delete
            </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default DeleteConfirmationPopover;
