import React, { useEffect, useState } from "react";
import {
  Badge,
  CardHeader,
  Flex,
  Grid,
  Text,
  VStack,
  useColorModeValue,
  Table,
  Tr,
  Td,
  Spacer,
  ButtonGroup,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import MainTable from "../../../components/MainTable";
import EditableGroupPanel from "./EditableGroupPanel";
import { Field, MainTableColumn, AccessTime } from "../../../types";
import { Row } from "@tanstack/react-table";
import { on } from "events";
import AddNewGroup from "../../../components/AddNewGroup";
import CheckboxList from "../../../components/CheckboxList";
import AccessTimesTable from "../../../components/AccessTimesTable";

interface DataItem {
  model: string;
  name: string;
  last_used: string;
  kwh: number;
  status: string;
  last_seen?: string;
}

const columns: MainTableColumn[] = [
  { title: "Model", field: "model" },
  { title: "Name", field: "name" },
  { title: "Last Used", field: "last_used" },
  { title: "kWh this month", field: "kwh" },
  {
    title: "Status",
    field: (rowData: DataItem) => (
      <StatusBadge value={rowData.status} value2={rowData.last_seen} />
    ),
  },
];

const data: DataItem[] = [
  {
    model: "AS4000",
    name: "Front A",
    last_used: "2024-04-13",
    kwh: 432,
    status: "Online",
  },
  {
    model: "AS4000",
    name: "Monthly",
    last_used: "In use",
    kwh: 298,
    status: "Online",
  },
  {
    model: "AS4000",
    name: "Weekly",
    last_used: "Today",
    kwh: 516,
    status: "Online",
  },
  {
    model: "AS5000",
    name: "Rear",
    last_used: "2023-12-17",
    kwh: 0,
    status: "Offline",
    last_seen: "2023-12-18",
  },
];

const fieldData: Field[] = [
  {
    key: "model",
    title: "Model",
    type: "string",
    value: "",
    required: true,
  },
  {
    key: "name",
    title: "Name",
    type: "radio",
    radioOptions: ["Weekly", "Monthly"],
    value: "Monthly",
  },
  {
    key: "last_used",
    title: "Last Used",
    type: "readonly",
    value: "2024-04-13",
  },
  {
    key: "kwh",
    title: "kWh this month",
    type: "number",
    value: "432",
  },
];

const accessTimesData: AccessTime[] = [
  {
    id: 1,
    start_time: "08:00",
    end_time: "17:00",
    sun: false,
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: false,
  },
  {
    id: 2,
    start_time: "09:00",
    end_time: "11:30",
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: true,
    sat: false,
  },
  {
    id: 3,
    start_time: "07:00",
    end_time: "19:00",
    sun: true,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: true,
  },
];

interface StatusBadgeProps {
  value: string;
  value2?: string;
}
/**
 * types of fields: string, number (maxKwh), radio (weekly/monthly)
 *
 * key: "name"
 * title: "Full Name"
 * type: "string"   type: "number"   type: "radio" + options: ["weekly", "monthly"]   type: "readonly"
 * value: "John Doe"
 */

const StatusBadge: React.FC<StatusBadgeProps> = ({ value, value2 }) => {
  const bgStatus = useColorModeValue("gray.300", "gray.600");
  const colorStatus = useColorModeValue("white", "gray.400");

  return (
    <VStack>
      <Badge colorScheme={value === "Online" ? "green" : "red"}>{value}</Badge>
      {value2 && <Text fontSize="sm">{`Since ${value2}`}</Text>}
    </VStack>
  );
};

const SessionStatusBadge: React.FC<{ value: string }> = ({ value }) => {
  const colorScheme =
    value === "Completed" ? "green" : value === "Denied" ? "red" : "blue";

  return (
    <VStack>
      <Badge colorScheme={colorScheme}>{value}</Badge>
    </VStack>
  );
};

const highlightCondition = function (row: Row<unknown>, columnId: string) {
  return columnId === "factor" && (row.getValue(columnId) as number) > 20;
};
export default function Chargers() {
  const [selectedRowId, setSelectedRowId] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [fields, setFields] = useState<Field[]>([]);
  const [newFields, setNewFields] = useState<Field[]>([]);

  const onFieldsChange = (newFields: Field[]) => {
    console.log("old fields:", fields);
    console.log("new fields:", newFields);
    setFields([...newFields]);
  };

  const onRowlSelect = (rowId: string) => {
    console.log("Selected row ID:", rowId);
    setSelectedRowId(rowId);
    const newData = data[parseInt(rowId)];
    const updatedFields = fields.map((field) => ({
      ...field,
      value: "" + newData[field.key as keyof DataItem],
    }));
    setFields(updatedFields);
  };

  const addNew = () => {
    const emptyFields = fields.map((field) => ({
      ...field,
      value: "",
    }));
    setNewFields(emptyFields);

    onOpen();
  };

  // TEMPORARY for testing
  useEffect(() => {
    setFields(fieldData);
  }, []);

  const rsColumns = [
    { title: "Start Time", field: "start_time" },
    {
      title: "Status",
      field: (rowData: any) => <SessionStatusBadge value={rowData.status} />,
    },
    { title: "User", field: "user" },
    { title: "kWh", field: "kwh" },
  ];

  const rsData = [
    {
      start_time: "2024-04-12 10:30am",
      status: "In Progress",
      user: "Paul Smith",
      kwh: 5,
    },

    {
      start_time: "2024-04-12 10:12am",
      status: "Completed",
      user: "Paul Smith",
      kwh: 12,
    },
    {
      start_time: "2024-04-12 7:18am",
      status: "Completed",
      user: "Ilana Holt",
      kwh: 26,
    },
    {
      start_time: "2024-04-12 4:18am",
      status: "Denied",
      user: "(unknown)",
      kwh: 0,
    },
  ];

  const cbItems = [
    { key: "name", label: "Name" },
    { key: "model", label: "Model" },
    { key: "last_used", label: "Last Used" },
    { key: "kwh", label: "kWh this month" },
    { key: "status", label: "Status" },
  ];
  const cbInitialCheckedItemKeys = ["name", "model", "kwy"];
  const cbOnFieldsChange = (checkedItems: string[]) => {
    console.log("checkedItems", checkedItems);
  };

  const [accessTimes, setAccessTimes] = useState<AccessTime[]>(accessTimesData);
  const onAccessTimesChange = (accessTimes: AccessTime[]) => {
    console.log("accessTimes", accessTimes);
    setAccessTimes(accessTimes);

  }

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Grid templateColumns="1fr" gap="24px" mb="24px">
        {/* MainTable occupies the full width of the first row */}
        <MainTable
          title={"Chargers"}
          columnDefs={columns}
          data={data}
          highlightCondition={highlightCondition}
          selectedRowId={selectedRowId}
          onRowSelect={onRowlSelect}
          onAdd={addNew}
        />
      </Grid>
      <Grid templateColumns="1fr 2fr" gap="24px">
        {/* Details occupies about 1/3 of the space on the second row */}
        <EditableGroupPanel
          title={"Details"}
          fields={fields}
          onFieldsChange={onFieldsChange}
        />
        {/* Sessions occupies about 2/3 of the space on the second row */}
        <MainTable
          title={"Recent Sessions"}
          columnDefs={rsColumns}
          data={rsData}
        />
      </Grid>
      <Grid templateColumns="1fr" gap="24px" mb="24px">
        <CheckboxList
          items={cbItems}
          initialCheckedItemKeys={cbInitialCheckedItemKeys}
          onFieldsChange={cbOnFieldsChange}
        />
      </Grid>
      <Grid templateColumns="1fr" gap="24px" mb="24px">
        <AccessTimesTable
          accessTimes={accessTimes}
          onChange={onAccessTimesChange}
        />
      </Grid>

      <AddNewGroup
        isOpen={isOpen}
        onClose={onClose}
        initialFields={newFields}
        onFieldsChange={onFieldsChange}
      />
    </Flex>
  );
}
