import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Stack,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";

interface CheckboxItem {
  key: string;
  label: string;
}

interface AddNewGroupProps {
  items: CheckboxItem[];
  initialCheckedItemKeys: string[]; // Ensure this is `string[]`
  onFieldsChange: (checkedItemKeys: string[]) => void; // Callback to send updated data to parent
}

const CheckboxList: React.FC<AddNewGroupProps> = ({
  items,
  initialCheckedItemKeys,
  onFieldsChange,
}) => {
  const [checkedItemKeys, setCheckedItemKeys] = useState<string[]>([]);

  useEffect(() => {
    setCheckedItemKeys(initialCheckedItemKeys); // Initialize checked keys from props
  }, [initialCheckedItemKeys]); // Fix dependency list

  const onChange = (newCheckedItemKeys: string[]) => {
    setCheckedItemKeys(newCheckedItemKeys);
    onFieldsChange(newCheckedItemKeys);
  };

  const boxColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Box
      p={4} // sets padding on all sides
      border="1px" // sets the thickness and style of the border
      borderColor={boxColor} // sets the color of the border
      borderRadius="lg" // applies a large border radius for rounded corners
    >
      <CheckboxGroup
        colorScheme="green"
        onChange={onChange}
        defaultValue={initialCheckedItemKeys} // Set default values
      >
        <VStack align="start">
          {items.map((item) => (
            <Checkbox key={item.key} value={item.key}>
              {item.label}
            </Checkbox>
          ))}
        </VStack>
      </CheckboxGroup>
    </Box>
  );
};

export default CheckboxList;
