import {
  Box,
  FormControl,
  FormErrorMessage,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { Field } from "../types";

interface EditableTextFieldProps {
  field: Field;
  onChange: (newValue: string, isValid: boolean) => void;
  isEditing: boolean;
}

const EditableTextField: React.FC<EditableTextFieldProps> = ({
  field,
  onChange,
  isEditing,
}) => {
  const [inputValue, setInputValue] = useState(field.value);
  const [error, setError] = useState("");

  const [hasBeenEdited, setHasBeenEdited] = useState(field.value !== "");

  const validateInput = useCallback(
    (value: string) => {
      if (field.required && !value.trim()) {
        setError("This field is required");
        field.isValid = false;
      } else {
        setError("");
        field.isValid = true;
      }
    },
    [field]
  );

  useEffect(() => {
    setInputValue(field.value);
    validateInput(field.value); // Validate input when field value changes externally
  }, [field.value, validateInput]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasBeenEdited(true);
    const value = e.target.value;
    setInputValue(value);
    validateInput(value);
    onChange(value, !!field.isValid); // Ensure field.isValid is always a boolean
  };

  const handleRadioChange = (value: string) => {
    setInputValue(value);
    onChange(value, true);
  };

  const inputMode = field.type === "number" ? "numeric" : "text";
  const inputType = field.type === "number" ? "number" : "text";

  return (
    <FormControl isInvalid={!!error}>
      {isEditing && field.type !== "readonly" ? (
        field.type === "radio" ? (
          <Box borderWidth="1px" p={1.5}>
            <RadioGroup
              onChange={handleRadioChange}
              value={inputValue}
              size="sm"
            >
              <Stack direction="row">
                {field.radioOptions?.map((option) => (
                  <Radio key={option} value={option}>
                    {option}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </Box>
        ) : (
          <Input
            value={inputValue}
            inputMode={inputMode}
            type={inputType}
            onChange={handleChange}
            size="sm"
          />
        )
      ) : (
        <Text ml={2} py={1.5}>
          {field.value}
        </Text>
      )}
      {error && hasBeenEdited && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default EditableTextField;
