/* eslint-disable react/prop-types */
import React from "react";
// Chakra imports
import { Flex, Text } from "@chakra-ui/react";

function Tables() {
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Text>zib!</Text>
    </Flex>
  );
}

export default Tables;
