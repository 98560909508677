import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

// class BarChart extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       chartData: [],
//       chartOptions: {},
//     };
//   }

//   componentDidMount() {
//     this.setState({
//       chartData: barChartData,
//       chartOptions: barChartOptions,
//     });
//   }

//   render() {
//     return (
//       <Card
//         py="1rem"
//         height={{ sm: "200px" }}
//         width="100%"
//         bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
//         position="relative"
//       >
//         <Chart
//           options={this.state.chartOptions}
//           series={this.state.chartData}
//           type="bar"
//           width="100%"
//           height="100%"
//         />
//       </Card>
//     );
//   }
// }

// export default BarChart;

 class BarChart extends React.Component {
   constructor(props) {
     super(props);

     this.state = {
       series: [
         {
           name: "Inflation",
           data: [1230, 1310, 1418, 1010, 1400, 1360, 902, 1230, 1140, 1008, 1050, 1026],
         },
       ],
       options: {
         chart: {
           height: 350,
           type: "bar",
         },
         plotOptions: {
           bar: {
             borderRadius: 10,
             dataLabels: {
               position: "top", // top, center, bottom
             },
           },
         },
         dataLabels: {
           enabled: true,
          //  formatter: function (val) {
          //    return val + "%";
          //  },
           offsetY: -20,
           style: {
             fontSize: "12px",
             colors: ["#304758"],
           },
         },

         xaxis: {
           categories: [
             "Jan",
             "Feb",
             "Mar",
             "Apr",
             "May",
             "Jun",
             "Jul",
             "Aug",
             "Sep",
             "Oct",
             "Nov",
             "Dec",
           ],
           position: "bottom",
           axisBorder: {
             show: false,
           },
           axisTicks: {
             show: false,
           },
           crosshairs: {
             fill: {
               type: "gradient",
               gradient: {
                 colorFrom: "#D8E3F0",
                 colorTo: "#BED1E6",
                 stops: [0, 100],
                 opacityFrom: 0.4,
                 opacityTo: 0.5,
               },
             },
           },
           tooltip: {
             enabled: true,
           },
         },
         yaxis: {
           axisBorder: {
             show: false,
           },
           axisTicks: {
             show: false,
           },
           labels: {
             show: true,
            //  formatter: function (val) {
            //    return val + "%";
            //  },
           },
         },
        //  title: {
        //    text: "Monthly Inflation in Argentina, 2002",
        //    floating: true,
        //    offsetY: 330,
        //    align: "center",
        //    style: {
        //      color: "#444",
        //    },
        //  },
       },
     };
   }

   render() {
     return (
       <div>
         <div id="chart">
           <ReactApexChart
             options={this.state.options}
             series={this.state.series}
             type="bar"
             height={350}
           />
         </div>
         <div id="html-dist"></div>
       </div>
     );
   }
 }

 export default BarChart;