import React from "react";
import { ChakraProvider, Flex, Text } from "@chakra-ui/react";
import ImageUpload from "../../../components/ImageUpload";

const App: React.FC = () => {
  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <ImageUpload />
    </Flex>
  );
};

export default App;
