import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import { Box, Flex, Button, FormControl, FormLabel, Heading, Input, Link, Text, useColorModeValue, useToast, Spinner } from "@chakra-ui/react";
import signInImage from "../../assets/img/busybg.png";
import { useUser } from "../../contexts/UserContext";
import { sendLoginLink } from "../../api";

// import axios from "axios"; // Assuming axios for HTTP requests

function SignIn() {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const titleColor = useColorModeValue("blue.500", "blue.200");
  const textColor = useColorModeValue("gray.400", "white");

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const sendEmail = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    setIsLoading(true);
    try {
      const resp = await sendLoginLink(email);
      toast({
        title: "Login link sent",
        description: "Check your email for the login link",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Login failed", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleChange = (setter) => (event) => {
    setError(""); // Clear any error messages when the user starts typing
    const value = event.target.value;
    setter(value);
    if (setter === setEmail) {
      setIsValidEmail(validateEmail(value));
    }
  };

  return (
    <Flex position="relative" mb="40px">
      <Flex h={{ sm: "initial", md: "75vh", lg: "85vh" }} w="100%" maxW="1044px" mx="auto" justifyContent="space-between" mb="30px" pt={{ sm: "100px", md: "0px" }}>
        {/* Remaining unchanged code for layout */}
        <Flex alignItems="center" justifyContent="start" style={{ userSelect: "none" }} w={{ base: "100%", md: "50%", lg: "42%" }}>
          <Flex direction="column" w="100%" background="transparent" p="48px" mt={{ md: "150px", lg: "80px" }}>
            <Heading color={titleColor} fontSize="32px" mb="10px">
              Welcome Back
            </Heading>
            <FormControl as="form" onSubmit={sendEmail}>
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Email
              </FormLabel>
              <Input borderRadius="15px" mb="24px" fontSize="sm" type="text" placeholder="Your email address" size="lg" value={email} onChange={handleChange(setEmail)} />
              <Text color="red.500" textAlign="center" height="10" mt="0">
                {error}{" "}
              </Text>

              <Button
                fontSize="20px"
                type="submit"
                bg="blue.500"
                w="100%"
                h="45"
                mb="20px"
                color="white"
                mt="20px"
                _hover={{ bg: "blue.200" }}
                _active={{ bg: "blue.400" }}
                isLoading={isLoading}
                loadingText="Sending"
                isDisabled={!isValidEmail}
              >
                Send Login Link
              </Button>
            </FormControl>
            <Text mb="36px" ms="4px" color={textColor} fontWeight="bold" fontSize="14px">
              A login link will be emailed to you.
            </Text>
            {/* <Flex flexDirection="column" justifyContent="center" alignItems="center" maxW="100%" mt="0px">
              <Text color={textColor} fontWeight="medium">
                Don't have an account?
                <Link color={titleColor} as="span" ms="5px" fontWeight="bold">
                  Sign Up
                </Link>
              </Text>
            </Flex> */}
          </Flex>
        </Flex>
        {/* Image Box code remains unchanged */}
        <Box display={{ base: "none", md: "block" }} overflowX="hidden" h="100%" w="40vw" position="absolute" right="0px">
          <Box bgImage={signInImage} w="100%" h="100%" bgSize="cover" bgPosition="50%" position="absolute" borderBottomLeftRadius="20px"></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
