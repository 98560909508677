import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Text,
  Image,
  Input,
  useToast,
  Flex,
} from "@chakra-ui/react";
import axios from "axios";
import { FaXmark, FaCheck } from "react-icons/fa6";

const ImageUpload: React.FC = () => {
  const [imageSrc, setImageSrc] = useState<string | ArrayBuffer | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [convertedFileUrl, setConvertedFileUrl] = useState<string | null>(null);
  const [imageWidth, setImageWidth] = useState<number | null>(null);
  const [imageHeight, setImageHeight] = useState<number | null>(null);
  const [imageSize, setImageSize] = useState<number | null>(null);
  const [imageType, setImageType] = useState<string | null>(null);
  const [imgType, setImgType] = useState<string | null>(null);
  const [typeOk, setTypeOk] = useState<boolean>(false);
  const [sizeOk, setSizeOk] = useState<boolean>(false);
  const [fileSizeOk, setFileSizeOk] = useState<boolean>(false);
  const [allOk, setAllOk] = useState<boolean>(false);

  // uploading/downloading
  const [status, setStatus] = useState<string | null>("");

  const toast = useToast();

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file && file.type.startsWith("image")) {
      setFile(file);
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        setImageSize(file.size);
        setImageType(file.type);
        const img = new window.Image();
        img.onload = () => {
          setImageWidth(img.width);
          setImageHeight(img.height);
        };

        if (e.target?.result) {
          setImageSrc(e.target.result);
          img.src = e.target.result as string;
        }
      };
      reader.readAsDataURL(file);
    } else {
      toast({
        title: "Error",
        description: "Not a valid image file.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const type = imageType?.split("/")[1]?.toUpperCase() || null;
    const typeOk = type === "BMP";
    const sizeOk = imageWidth === 2560 && imageHeight === 1440;
    const fileSizeOk = imageSize === 11059254;
    const allOk = typeOk && sizeOk && fileSizeOk;

    setImgType(type);
    setTypeOk(typeOk);
    setSizeOk(sizeOk);
    setFileSizeOk(fileSizeOk);
    setAllOk(allOk);
  }, [imageType, imageWidth, imageHeight, imageSize]);

  const API_SERVER = "http://dsapi.reliablepower.com";
  const handleConvert = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      try {
        const response = await axios.post(
          `${API_SERVER}/api/v1/images/convert`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            responseType: "blob", // Set the response type to 'blob'
            onUploadProgress: (progressEvent) => {
              const percentCompleted = progressEvent.total
                ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
                : 0;
              if (percentCompleted < 100) {
                setStatus(`Uploading: ${percentCompleted}%`);
              } else {
                setStatus("Processing");
              }
            },
          }
        );
        const imageName = file.name.split(".")[0];
        setStatus("Downloading");

        const blob = new Blob([response.data], { type: response.data.type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${imageName}.ddic`; // Change the file name and extension as needed
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        toast({
          title: "Conversion Failed",
          description: "Unable to convert image.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      setStatus("");
    }
  };

  const getOkIcon = (ok: boolean) => {
    return ok ? (
      <FaCheck size="20" color="green" />
    ) : (
      <FaXmark size="20" color="red" />
    );
  };

  return (
    <Box p={5} borderWidth="1px" borderRadius="lg">
      Only 24-bit color depth BMP files are supported. The size must be 2560 x
      1440. The filesize should be 11059254 bytes.
      <Input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        p={2}
        mb={4}
      />
      {imageSrc && (
        <>
          <Image
            src={imageSrc as string}
            alt="Preview"
            borderRadius="lg"
            maxW="full"
            maxH="300px"
            objectFit="cover"
          />

          <Flex align="center" mt={5}>
            {getOkIcon(typeOk)}
            <Text ml={2}>Type: {imgType}</Text>
            {!typeOk && (
              <Text ml={5} color="red" fontStyle="italic">
                Must be BMP
              </Text>
            )}
          </Flex>

          <Flex align="center" mt={5}>
            {getOkIcon(sizeOk)}
            <Text ml={2}>
              Size: {imageWidth} x {imageHeight}
            </Text>
            {!sizeOk && (
              <Text ml={5} color="red" fontStyle="italic">
                Must be 2560x1440
              </Text>
            )}
          </Flex>

          <Flex align="center" mt={5}>
            {getOkIcon(fileSizeOk)}
            <Text ml={2}>File size: {imageSize} bytes</Text>
            {!fileSizeOk && (
              <Text ml={5} color="red" fontStyle="italic">
                Must be 11059254 bytes
              </Text>
            )}
          </Flex>

          <Text mt={2}>{status}</Text>

          <Button
            isDisabled={!allOk}
            onClick={handleConvert}
            mt={4}
            colorScheme="blue"
          >
            Convert
          </Button>
        </>
      )}
    </Box>
  );
};

export default ImageUpload;
