import React from "react";
import { Box } from "@chakra-ui/react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const DualSeriesChart: React.FC = () => {
  // Define the options for the chart
  const options: ApexOptions = {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "left",
    },
    xaxis: {
        categories: ['','','','','','','','','','','','',
        "Mon",'','','','','','','','','','','','','','','','','','','','','','',
        "Tue",'','','','','','','','','','','','','','','','','','','','','','',
        "Wed",'','','','','','','','','','',],
    },
    stroke: {
      curve: "smooth",
       width: 1,
    },
    // fill: {
    //   type: "solid",
    // //   colors: ["green", "blue"],
    // opacity: 0.5,
    // },
    // title: {
    //   text: "Charger Utilization",
    //   align: "left",
    // },
    dataLabels: {
      enabled: false,
    },
  };

  // Define the series data
  const series = [
    {
      name: "Available Time",
      data: [
        0, 0, 0, 0, 0, 0, 6, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 6, 6, 6, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 6, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 6, 6,
        6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13,
        6, 6, 6, 0, 0, 0,
      ],
    },
    {
      name: "Used Time",
      data: [
        0, 0, 0, 0, 0, 0, 1.1, 4.5, 10.7, 10.7, 10.5, 6.4, 8.4, 9.1, 9.1, 8.9,
        4.4, 1.9, 0.2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1.6, 4.3, 11.2, 11.2, 9.4,
        3.8, 8.0, 8.8, 10.1, 9.9, 4.1, 1.6, 0.4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        1.2, 3.8, 12.8, 12.8, 11.8, 3.4, 8.4, 9.2, 8.8, 9.0, 4.2, 1.7, 0.2, 0, 0,
        0, 0,
      ],
    },
  ];

  // Render the chart inside a Chakra UI Box component
  return (
    // <Box pt={4} shadow="base" borderWidth="1px" borderRadius="lg" bg={"pink"}>
      <Chart
        options={options}
        series={series}
        type="area"
        width="100%"
        height="252"
        bg={"pink"}
      />
  );
};

export default DualSeriesChart;
