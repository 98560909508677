import React from "react";
import _ from "lodash";
import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr, Text } from "@chakra-ui/react";
import { getLogs } from "../../../api";

const Logs = () => {
  const [jsonData, setJsonData] = React.useState<any>({ items: [] });

  React.useEffect(() => {
    const fetchData = async () => {
      const data = await getLogs();
      console.log("ZZZZZZZZZZZZZZZZZZZZZZZZZZZ data", data);
      setJsonData(data);
    };
    fetchData();
  }, []);

  const formatDateTime = (timestamp: string | number | Date) => {
    try {
      const date = new Date(timestamp);
      return date.toLocaleString();
    } catch {
      return "";
    }
  };
  const formatRemainingData = (data: any) => {
    if (!data) return "";

    // Create a copy of data to avoid modifying the original
    const remainingData = { ...data };

    const d = remainingData.event === "deviceList add";
    console.log("ZZZZZZZZZZZZZZZZZZZZZZZZZZZ d", d);

    // Remove module and event as they're shown in other columns
    delete remainingData.module;
    delete remainingData.event;
    // Function to flatten nested objects
    function flattenObjectToString(obj: any) {
      const result: string[] = [];

      function process(obj: object, prefix = "") {
        for (const [key, value] of Object.entries(obj)) {
          if (Array.isArray(value)) {
            value.forEach((item, index) => {
              if (typeof item === "object") {
                process(item, `${key}[${index}].`);
              } else {
                result.push(`${key}[${index}]: ${item}`);
              }
              result.push("\n");
            });
          } else if (typeof value === "object" && value !== null) {
            process(value, `${key}.`);
          } else {
            result.push(`${prefix}${key}: ${value}`);
          }
        }
      }

      process(obj);
      return result.join(", ").replaceAll("\n, ", "\n");
    } // Flatten and format the remaining data
    const flatData = flattenObjectToString(remainingData);
    if (d) {
      console.log("ZZZZZZZZZZZZZZZZZZZZZZZZZZZ remainingData", remainingData);
      console.log("ZZZZZZZZZZZZZZZZZZZZZZZZZZZ flatData", flatData);
    }
    return flatData;
    return Object.entries(flatData)
      .map(([key, value]) => `${key.slice(5)}: ${value}`)
      .join(", ");
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Box overflowX="auto">
        <Table variant="simple" width="full" size="sm" style={{ borderCollapse: "collapse" }}>
          <Thead>
            <Tr bg="gray.100">
              <Th borderWidth="1px" py={1} borderBottom="1px solid" borderColor="gray.300">
                Timestamp
              </Th>
              <Th borderWidth="1px" py={1} borderBottom="1px solid" borderColor="gray.300">
                Module
              </Th>
              <Th borderWidth="1px" py={1} borderBottom="1px solid" borderColor="gray.300">
                Event
              </Th>
              <Th borderWidth="1px" py={1} borderBottom="1px solid" borderColor="gray.300">
                Additional Data
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {jsonData.items.map((item: any, index: number) => (
              <Tr key={item.id || index} _hover={{ bg: "gray.50" }}>
                <Td borderWidth="1px" py={1} whiteSpace="nowrap" borderBottom="1px solid" borderColor="gray.300">
                  {formatDateTime(item.timestamp)}
                </Td>
                <Td borderWidth="1px" py={1} whiteSpace="nowrap" borderBottom="1px solid" borderColor="gray.300">
                  {_.get(item, "data.module", "")}
                </Td>
                <Td borderWidth="1px" py={1} whiteSpace="nowrap" borderBottom="1px solid" borderColor="gray.300">
                  {_.get(item, "data.event", "")}
                </Td>
                <Td borderWidth="1px" py={1} borderBottom="1px solid" borderColor="gray.300">
                  <Text as="pre" fontFamily="mono" fontSize="sm" whiteSpace="pre-wrap">
                    {formatRemainingData(item.data)}
                  </Text>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Flex>
  );
};

export default Logs;
