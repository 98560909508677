import * as React from "react";
import { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Card,
  CardBody,
  CardHeader,
  Heading,
  useColorModeValue,
  Input,
  Checkbox,
  Button,
  Flex,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverFooter,
  ButtonGroup,
} from "@chakra-ui/react";

import { AccessTime } from "../types";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import DeleteConfirmationPopover from "./DeleteConfirmationPopover";

interface AccessTimesTableProps {
  accessTimes: AccessTime[];
  onChange: (accessTimes: AccessTime[]) => void;
}

const AccessTimesTable: React.FC<AccessTimesTableProps> = ({ accessTimes, onChange }) => {
  const titleBorderColor = useColorModeValue("#E2E8F0", "#4A5568");

  const [targetId, setTargetId] = useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  // Handler for checkbox changes

  const handleCheckboxChange = (accessTime: AccessTime, field: keyof AccessTime, value: boolean | string) => {
    const newAccessTimes = accessTimes.map((item) => {
      if (item === accessTime) {
        return { ...item, [field]: value };
      }
      return item;
    });
    onChange(newAccessTimes);
  };

  const handleDelete = (id: number) => {
    const filteredAccessTimes = accessTimes.filter((item) => item.id !== id);
    onChange(filteredAccessTimes);
  };

  const handleAdd = () => {
    const newAccessTime: AccessTime = {
      id: Math.random(), // Generates a pseudo-random ID
      start_time: "",
      end_time: "",
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
    };
    onChange([...accessTimes, newAccessTime]);
  };

  const showAdd = accessTimes.length === 0 || (accessTimes[accessTimes.length - 1].start_time !== "" && accessTimes[accessTimes.length - 1].end_time !== "");

  return (
    <Card maxH="100%">
      <CardHeader pb={0}>
        <Flex justifyContent="space-between" alignItems="center" width="100%">
          <Heading size="md">Access Times</Heading>
          <Button variant="outline" onClick={handleAdd} colorScheme="blue" size="sm" leftIcon={<AddIcon />} isDisabled={!showAdd}>
            Add
          </Button>
        </Flex>
      </CardHeader>
      <CardBody>
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              {["Start", "End", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", ""].map((header) => (
                <Th key={header} verticalAlign="middle" style={{ borderColor: titleBorderColor }}>
                  {header}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {accessTimes.map((row) => (
              <Tr key={row.id}>
                {["start_time", "end_time"].map((field) => (
                  <Td key={field} p={0} m={0}>
                    <Input
                      type="time"
                      value={row[field as keyof AccessTime] as string}
                      onChange={(e) => handleCheckboxChange(row, field as keyof AccessTime, e.target.value)}
                      color={!row[field as keyof AccessTime] ? "red" : ""}
                    />
                  </Td>
                ))}
                {["sun", "mon", "tue", "wed", "thu", "fri", "sat"].map((field) => (
                  <Td key={field}>
                    <Checkbox isChecked={!!row[field as keyof AccessTime]} onChange={(e) => handleCheckboxChange(row, field as keyof AccessTime, e.target.checked)}></Checkbox>
                  </Td>
                ))}
                <Td>
                  <DeleteConfirmationPopover
                    targetId={row.id}
                    isOpen={isOpen && targetId === row.id}
                    onOpen={() => {
                      setTargetId(row.id);
                      open();
                    }}
                    onClose={close}
                    onDelete={handleDelete}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default AccessTimesTable;
