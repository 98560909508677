import {
  Badge,
  Card,
  Flex,
  Grid,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Row } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import AccessTimesTable from "../../../components/AccessTimesTable";
import AddNewGroup from "../../../components/AddNewGroup";
import CheckboxList from "../../../components/CheckboxList";
import MainTable from "../../../components/MainTable";
import { AccessTime, Field, MainTableColumn } from "../../../types";
import EditableGroupPanel from "./EditableGroupPanel";
import EditableGroup from "../../../components/EditableGroup";

interface DataItem {
  name: string;
  hours: string;
  maxtime: number;
  maxusage: number;
  period: string;
}

const columns: MainTableColumn[] = [
  { title: "Name", field: "name" },
  { title: "Hours", field: "hours" },
  {
    title: "Max Time",
    field: (rowData: DataItem) => (
      <Text>{rowData.maxtime === 0 ? "-" : `${rowData.maxtime} hrs`}</Text>
    ),
  },
  {
    title: "Max Usage",
    field: (rowData: DataItem) => (
      <Text>{rowData.maxusage === 0 ? "-" : `${rowData.maxusage} kWh`}</Text>
    ),
  },
  { title: "Period", field: "period" },
];

const data: DataItem[] = [
  {
    name: "IT",
    hours: "06:00-20:00 M-F",
    maxtime: 3,
    maxusage: 200,
    period: "Monthly",
  },
  {
    name: "Weekends",
    hours: "06:00-20:00 Sat, Sun",
    maxtime: 3,
    maxusage: 0,
    period: "Monthly",
  },
  {
    name: "Manuf Day",
    hours: "07:00-17:00 M-F",
    maxtime: 0,
    maxusage: 150,
    period: "Weekly",
  },
  {
    name: "Manuf Swing",
    hours: "01:00-23:00 M-F",
    maxtime: 0,
    maxusage: 150,
    period: "Weekly",
  },
  {
    name: "Manuf Night",
    hours: "21:00-07:00 M-F",
    maxtime: 0,
    maxusage: 180,
    period: "Weekly",
  },
];

const fieldData: Field[] = [
  {
    key: "name",
    title: "Name",
    type: "string",
    value: "",
    required: true,
  },
  {
    key: "hours",
    title: "Access Times",
    type: "readonly",
    value: "",
  },
  {
    key: "maxtime",
    title: "Max Time",
    type: "number",
    value: "",
  },
  {
    key: "maxusage",
    title: "Max Usage",
    type: "number",
    value: "432",
  },
  {
    key: "period",
    title: "Period",
    type: "radio",
    radioOptions: ["Weekly", "Monthly"],
    value: "Monthly",
  },
];

const accessTimesData: AccessTime[] = [
  {
    id: 1,
    start_time: "08:00",
    end_time: "17:00",
    sun: false,
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: false,
  },
  {
    id: 2,
    start_time: "09:00",
    end_time: "11:30",
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: true,
    sat: false,
  },
  {
    id: 3,
    start_time: "07:00",
    end_time: "19:00",
    sun: true,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: true,
  },
];

interface StatusBadgeProps {
  value: string;
  value2?: string;
}
/**
 * types of fields: string, number (maxKwh), radio (weekly/monthly)
 *
 * key: "name"
 * title: "Full Name"
 * type: "string"   type: "number"   type: "radio" + options: ["weekly", "monthly"]   type: "readonly"
 * value: "John Doe"
 */

const StatusBadge: React.FC<StatusBadgeProps> = ({ value, value2 }) => {
  const bgStatus = useColorModeValue("gray.300", "gray.600");
  const colorStatus = useColorModeValue("white", "gray.400");

  return (
    <VStack>
      <Badge colorScheme={value === "Online" ? "green" : "red"}>{value}</Badge>
      {value2 && <Text fontSize="sm">{`Since ${value2}`}</Text>}
    </VStack>
  );
};

const SessionStatusBadge: React.FC<{ value: string }> = ({ value }) => {
  const colorScheme =
    value === "Completed" ? "green" : value === "Denied" ? "red" : "blue";

  return (
    <VStack>
      <Badge colorScheme={colorScheme}>{value}</Badge>
    </VStack>
  );
};

const highlightCondition = function (row: Row<unknown>, columnId: string) {
  return columnId === "factor" && (row.getValue(columnId) as number) > 20;
};
export default function Chargers() {
  const [selectedRowId, setSelectedRowId] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [fields, setFields] = useState<Field[]>([]);
  const [newFields, setNewFields] = useState<Field[]>([]);

  const onFieldsChange = (newFields: Field[]) => {
    console.log("old fields:", fields);
    console.log("new fields:", newFields);
    setFields([...newFields]);
  };

  const onRowlSelect = (rowId: string) => {
    console.log("Selected row ID:", rowId);
    setSelectedRowId(rowId);
    const newData = data[parseInt(rowId)];
    const updatedFields = fields.map((field) => ({
      ...field,
      value: "" + newData[field.key as keyof DataItem],
    }));
    setFields(updatedFields);
  };

  const addNew = () => {
    const emptyFields = fields.map((field) => ({
      ...field,
      value: "",
    }));
    setNewFields(emptyFields);

    onOpen();
  };

  // TEMPORARY for testing
  useEffect(() => {
    setFields(fieldData);
  }, []);

  const rsColumns = [
    { title: "Start Time", field: "start_time" },
    {
      title: "Status",
      field: (rowData: any) => <SessionStatusBadge value={rowData.status} />,
    },
    { title: "User", field: "user" },
    { title: "kWh", field: "kwh" },
  ];

  const rsData = [
    {
      start_time: "2024-04-12 10:30am",
      status: "In Progress",
      user: "Paul Smith",
      kwh: 5,
    },

    {
      start_time: "2024-04-12 10:12am",
      status: "Completed",
      user: "Paul Smith",
      kwh: 12,
    },
    {
      start_time: "2024-04-12 7:18am",
      status: "Completed",
      user: "Ilana Holt",
      kwh: 26,
    },
    {
      start_time: "2024-04-12 4:18am",
      status: "Denied",
      user: "(unknown)",
      kwh: 0,
    },
  ];

  const cbItems = [
    { key: "name", label: "Front Entrance" },
    { key: "model", label: "Bldg B2" },
    { key: "last_used", label: "Bldg B3" },
  ];
  const cbInitialCheckedItemKeys = ["name", "model", "kwy"];
  const cbOnFieldsChange = (checkedItems: string[]) => {
    console.log("checkedItems", checkedItems);
  };

  const [accessTimes, setAccessTimes] = useState<AccessTime[]>(accessTimesData);
  const onAccessTimesChange = (accessTimes: AccessTime[]) => {
    console.log("accessTimes", accessTimes);
    setAccessTimes(accessTimes);
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Grid templateColumns="1fr" gap="24px" mb="24px">
        {/* MainTable occupies the full width of the first row */}
        <MainTable
          title={"Groups"}
          columnDefs={columns}
          data={data}
          highlightCondition={highlightCondition}
          selectedRowId={selectedRowId}
          onRowSelect={onRowlSelect}
          onAdd={addNew}
        />
      </Grid>
      <Card variant="outline" p={6} width="100%">
        <Flex mt="50" direction="column">
          <Heading size="md" mb="10px">
            Details
          </Heading>
          <EditableGroup
            initialFields={fields}
            onFieldsChange={onFieldsChange}
          />{" "}
        </Flex>
        <Flex mt="50" direction="column">
          <Heading size="md" mb="0">
            Locations
          </Heading>
          <Flex mt="5">
            <CheckboxList
              items={cbItems}
              initialCheckedItemKeys={cbInitialCheckedItemKeys}
              onFieldsChange={cbOnFieldsChange}
            />
          </Flex>
        </Flex>
        <Flex mt="50">
          <AccessTimesTable
            accessTimes={accessTimes}
            onChange={onAccessTimesChange}
          />
        </Flex>
      </Card>
      <AddNewGroup
        isOpen={isOpen}
        onClose={onClose}
        initialFields={newFields}
        onFieldsChange={onFieldsChange}
      />
    </Flex>
  );
}
