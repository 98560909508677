import React, { useState } from "react";

import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";
import { checkLoginToken } from "../../api";
import { Box, Flex, Button, FormControl, FormLabel, Heading, Input, Link, Text, useColorModeValue, useToast, Spinner } from "@chakra-ui/react";

const AuthUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setAuthToken } = useUser();
  console.log("Auth component");
  useEffect(() => {
    console.log("Auth useEffect");
    const doAuth = async () => {
      // Parse the query params or inspect the pathname to determine where to redirect
      const queryParams = new URLSearchParams(location.search);
      const login_token = queryParams.get("lt");
      console.log("login_token", login_token);

      if (!login_token) {
        console.log("missing login token");
        navigate("/login?msg=missing_login_token");
        return;
      }
      let data = null;
      try {
        data = await checkLoginToken(login_token);
      } catch (error) {
        console.error("Login failed", error);
        navigate("/login?msg=invalid_login_token");
        return;
      }

      if (!data.token) {
        console.error("Login failed: no token");
        navigate("/login?msg=no_token");
        return;
      }
      setAuthToken(data.token);

      console.log("AuthUser response data", data);
      if (data.name) {
        navigate("/admin/home");
      } else {
        navigate("/first-time");
      }
    };
    doAuth();
  }, [location, navigate, setAuthToken]);

  console.log("Auth return null");
  return <Text>AuthUser</Text>;
  // return null;
  // No need to render anything in this component
};

export default AuthUser;
