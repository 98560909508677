import React from "react";

import { Flex } from "@chakra-ui/react";
import ReactApexChart from "react-apexcharts";

function DonutChart() {
  // Initialize state using useState hook
  const seriesd = [44, 55, 13];
  const chartData = {
    series: seriesd,
    options: {
      legend: {
        position: "bottom" as const,
        formatter: function (seriesName: any, opts: any) {
          return `${seriesName}: ${opts.w.globals.series[opts.seriesIndex]}`;
        },
      },
      dataLabels: {
        enabled: false,
      },

      plotOptions: {
        pie: {
          donut: {
            size: "65%",
            labels: {
              show: true,
              name: {
                show: true,
              },
              value: {
                show: true,
              },
              total: {
                show: true,
                label: "Total",
                fontSize: "22px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: "#373d3f",
                formatter: function (w: any) {
                  return w.globals.seriesTotals.reduce((a: number, b: number) => {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
      labels: ["In Use", "Available", "Offline"],
    },
  };

  // Render the component
  return (
    // <Box p={4} bg={"cyan"} shadow="base" borderWidth="1px" borderRadius="lg" height="100%">
    <Flex>
      <div id="chart">
        <ReactApexChart options={chartData.options} series={chartData.series} type="donut" height={300} />
      </div>
      <div id="html-dist"></div>
    </Flex>
  );
}

export default DonutChart;
