import axios from "axios";
import { Server } from "http";
import Pages from "layouts/Auth";
import { NavigateFunction } from "react-router-dom";
import { AppLogDataType, UserDataType } from "types";
// import { UserDataType } from "../types";
// import { CategoryType, ListingDataType } from "types";

const BASE_URL = "https://dsapi.reliablepower.com/api/v1";
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const setJwt = (token: string) => {
  localStorage.setItem("jwt", token);
  addJwt();
};

export const clearJwt = () => {
  localStorage.removeItem("jwt");
};
console.log("ZZZZZZZZZZZZZZZZZZZZZZZZZZZ");
// Add a request interceptor to add the JWT token to the headers
const addJwt = () => {
  api.interceptors.request.use((config) => {
    const token = localStorage.getItem("jwt");
    console.log("About to add JWT token to headers", token);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
};

const token = localStorage.getItem("jwt");
console.log("ZZZZZZZZZZZZZZZZZZZZZZZZZZZ token", token);
if (token) {
  addJwt();
}
// Add a response interceptor to handle 401 errors
// export const setupAxiosInterceptor = (navigate: NavigateFunction) => {
//   console.log("Setting up axios interceptor");

//   // Add new interceptor
//   api.interceptors.response.use(
//     (response) => response,
//     (error) => {
//       console.log("Axios interceptor error", error?.response?.status);
//       if (error?.response?.status === 401) {
//         localStorage.removeItem("jwt");
//         navigate("/login");
//       }
//       return Promise.reject(error);
//     }
//   );
// };

/* ***********
Can log in and stay logged in
- find a way to expire old tokens
- start building out the Pages
- add a logout button
**************/

export const setupAxiosInterceptor = (navigate: NavigateFunction) => {
  console.log("Setting up axios interceptor");

  // Remove existing interceptors
  api.interceptors.response.clear();

  // Add new interceptor with explicit error handling
  const interceptorId = api.interceptors.response.use(
    (response) => {
      // Handle successful responses
      console.log("Response Interceptor:", response);
      return response;
    },
    (error) => {
      console.log("Interceptor triggered:", error);

      // Check if the error has a response
      if (error?.response) {
        console.log("Status:", error.response.status);

        if (error.response.status === 401) {
          console.log("401 detected - clearing JWT and redirecting");
          clearJwt();
          navigate("/login");
        }
      }

      return Promise.reject(error);
    }
  );

  console.log("Interceptor set up with ID:", interceptorId);
};
export const sendLoginLink = async (email: string) => {
  return api.post("/auth", { email });
};

export const getUserData = async (): Promise<UserDataType | null> => {
  // console.log("xxxxx api.defaults.headers.common['Authorization']", api.defaults.headers.common["Authorization"]);
  // console.log("xxxxx localStorage.getItem('jwt')", localStorage.getItem("jwt"));
  if (!api.defaults.headers.common["Authorization"]) {
    addJwt();
  }
  try {
    console.log("ZZ1");
    const response = await api.get("/users/0");
    console.log("ZZ2", response.data);
    return response.data;
  } catch (error) {
    console.log("ZZ3");
    console.error("getUserData error:", error);
    return null;
  }
};

export const checkLoginToken = async (loginToken: string): Promise<UserDataType> => {
  const resp = await api.get(`/auth?lt=${loginToken}`);
  console.log("checkLoginToken resp", resp);
  return resp.data;
};

export const getLogs = async (): Promise<AppLogDataType> => {
  try {
    const resp = await api.get("/app_logs");
    return resp.data;
  } catch (error) {
    console.error("getLogs error:", error);
    return { items: [] };
  }
};
// export const getUserListings = async (userId: string) => {
//   return api.get(`/listings?userId=${userId}`);
// };
// export const updateUserDetails = async (userId: string, userDetails: UserDataType) => {
//   return api.put(`/users/${userId}`, userDetails);
// };

// export const getAllListings = async (): Promise<ListingDataType[]> => {
//   const resp = await api.get("/listings");
//   if (!resp) {
//     return [];
//   }

//   const listings = resp.data.items as ListingDataType[];

//   for (const listing of listings) {
//     listing.photoUrls = [];
//     // Loop through each photo_id in photo_ids and add the corresponding photo URL
//     for (const photoId of listing.photo_ids) {
//       listing.photoUrls.push(getPhotoUrl(listing.id, photoId));
//     }
//   }
//   return listings;
// };

// export const getListing = async (listingId: string): Promise<ListingDataType | null> => {
//   const response = await api.get(`/listings/${listingId}`);
//   if (!response) {
//     return null;
//   }

//   const listing = response.data as ListingDataType;
//   listing.photoUrls = [];

//   // Loop through each photo_id in photo_ids and Add the corresponding photo URL
//   for (const photoId of listing.photo_ids) {
//     listing.photoUrls.push(getPhotoUrl(listing.id, photoId));
//   }
//   return listing;
// };

// export const addListing = async (listing: any) => {
//   return api.post("/listings", listing);
// };

// export const updateListing = async (listingId: string, listing: any) => {
//   return api.put(`/listings/${listingId}`, listing);
// };
