import React, { useEffect, useState } from "react";
import {
  Flex,
  IconButton,
  ButtonGroup,
  Text,
  Table,
  Tr,
  Td,
  Spacer,
  useColorModeValue,
  Tbody,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
} from "@chakra-ui/react";
import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import EditableTextField from "./EditableTextField";
import { Field } from "../types";

interface AddNewGroupProps {
  initialFields: Field[];
  onFieldsChange: (fields: Field[]) => void; // Callback to send updated fields to parent
  isOpen: boolean;
  onClose: () => void;
}

const AddNewGroup: React.FC<AddNewGroupProps> = ({
  initialFields,
  onFieldsChange,
  isOpen,
  onClose,
}) => {
  const [fields, setFields] = useState<Field[]>(initialFields);

  const handleSave = (fieldName: string) => (newValue: string) => {
    const newFields = fields.map((field) =>
      field.key === fieldName ? { ...field, value: newValue } : field
    );
    setFields(newFields);
  };

  const saveAll = () => {
    const allValid = fields.every((field) => field.isValid !== false);
    if (!allValid) {
      console.error("Not all fields are valid");
      return;
    }
    onFieldsChange(fields); // Trigger callback with updated fields
    onClose();
  };

    useEffect(() => {
      // we got new fields to display
      setFields([...initialFields]);
    }, [initialFields]);


  const textColor = useColorModeValue("gray.700", "white");

  const allValid = fields.every((field) => field.isValid !== false);

  const tdStyle = {
    px: 2, // padding left and right
    py: 2, // padding top and bottom
  };

  console.log("fields", fields)
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Item</ModalHeader>
        <ModalBody>
          <Flex width="100%">
            <Table
              variant="unstyled"
              size="md"
              sx={{ tableLayout: "auto", width: "auto" }}
            >
              <Tbody>
                {fields.filter(field => field.type !== "readonly").map((field) => (
                  <Tr key={field.key}>
                    <Td sx={tdStyle}>
                      <Text fontSize="md" color={textColor} fontWeight="bold">
                        {field.title}:
                      </Text>
                    </Td>
                    <Td sx={tdStyle}>
                      <EditableTextField
                        field={field}
                        onChange={handleSave(field.key)}
                        isEditing={true}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Spacer />
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={saveAll}
            isDisabled={!allValid}
          >
            Add
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddNewGroup;
