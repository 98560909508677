// Chakra imports
import React from "react";
import { Card, CardBody, CardHeader, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import EditableGroup from "../../../components/EditableGroup";
import { Field } from "../../../types";

export type EditableGroupPanelProps = {
  title: string;
  fields: Field[];
  onFieldsChange: (fields: Field[]) => void;
};

const EditableGroupPanel = ({ title, fields, onFieldsChange }: EditableGroupPanelProps) => {
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          {title}
        </Text>
      </CardHeader>
      <CardBody px="5px">
        <EditableGroup initialFields={fields} onFieldsChange={onFieldsChange} />{" "}
      </CardBody>
    </Card>
  );
};

export default EditableGroupPanel;
