// import
import React from "react";
import SignIn from "./views/Auth/SignIn.js";
import SignUp from "./views/Auth/SignUp.js";
// import Billing from "./views/Dashboard/Billing";
import DashMain from "./views/Dashboard/DashMain/index.js";
// import Profile from "./views/Dashboard/Profile";
import Tables from "./views/Dashboard/Tables/index.js";
import Home from "./views/Dashboard/Home/index.js";
import Chargers from "./views/Dashboard/Chargers/index";
import Users from "./views/Dashboard/Users/index";
import Cards from "./views/Dashboard/Cards/index.js";
import Groups from "./views/Dashboard/Groups/index";
import Locations from "./views/Dashboard/Locations/index.js";
import Convert from "./views/Dashboard/Convert/index";
import Logs from "./views/Dashboard/Logs/index";

import { FaChargingStation, FaLocationDot, FaUser, FaUsers, FaFileImage, FaFileLines } from "react-icons/fa6";
import { RiBankCard2Line } from "react-icons/ri";

import { DocumentIcon, HomeIcon, PersonIcon, RocketIcon } from "./components/Icons/Icons.js";
import AuthUser from "./views/Auth/AuthUser";

const dashRoutes = [
  {
    path: "/dashboard",
    name: "zDash",
    icon: <HomeIcon color="inherit" />,
    component: DashMain,
    layout: "/admin",
  },
  {
    path: "/tables",
    name: "zTables",
    icon: <HomeIcon />,
    component: Tables,
    layout: "/admin",
  },
  {
    path: "/convert",
    name: "Convert",
    icon: <FaFileImage />,
    component: Convert,
    layout: "/admin",
  },

  {
    path: "/home",
    name: "Home",
    icon: <HomeIcon />,
    component: Home,
    layout: "/admin",
  },
  {
    path: "/chargers",
    name: "Chargers",
    icon: <FaChargingStation />,
    component: Chargers,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: <FaUser />,
    component: Users,
    layout: "/admin",
  },
  {
    path: "/cards",
    name: "Cards",
    icon: <RiBankCard2Line />,
    component: Cards,
    layout: "/admin",
  },
  {
    path: "/groups",
    name: "Groups",
    icon: <FaUsers />,
    component: Groups,
    layout: "/admin",
  },
  {
    path: "/locations",
    name: "Locations",
    icon: <FaLocationDot />,
    component: Locations,
    layout: "/admin",
  },
  {
    path: "/signin",
    name: "Sign In",
    icon: <DocumentIcon color="inherit" />,
    component: SignIn,
    layout: "/auth",
  },
  {
    path: "/",
    name: "Auth",
    icon: <DocumentIcon color="inherit" />,
    component: AuthUser,
    layout: "/auth",
  },
  {
    path: "/logs",
    name: "Logs",
    icon: <FaFileLines />,
    component: Logs,
    layout: "/admin",
  },

  // {
  //   name: "ACCOUNT PAGES",
  //   category: "account",
  //   rtlName: "صفحات",
  //   state: "pageCollapse",
  //   views: [
  //     // {
  //     //   path: "/profile",
  //     //   name: "Profile",
  //     //   rtlName: "لوحة القيادة",
  //     //   icon: <PersonIcon color="inherit" />,
  //     //   secondaryNavbar: true,
  //     //   component: Profile,
  //     //   layout: "/admin",
  //     // },
  //     {
  //       path: "/signin",
  //       name: "Sign In",
  //       icon: <DocumentIcon color="inherit" />,
  //       component: SignIn,
  //       layout: "/auth",
  //     },
  //     // {
  //     //   path: "/signup",
  //     //   name: "Sign Up",
  //     //   icon: <RocketIcon color="inherit" />,
  //     //   secondaryNavbar: true,
  //     //   component: SignUp,
  //     //   layout: "/auth",
  //     // },
  //   ],
  // },
];
export default dashRoutes;
